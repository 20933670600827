import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/main-layout.js"
import Row from "../components/row.js"
import HeaderImage from "../components/elements/header-image.js"
import HTitle from "../components/elements/h-title.js"

const Page = ({ location, data }) => (
  <>
    <SEO title="404" location={location} />
    <Layout location={location}>
      <Row xFull hFull>
        <HeaderImage image={data.logo.childImageSharp.fluid}>
          <div className="flex w-full h-full items-center justify-center text-fluid">
            <div className="max-w-3/4 bg-white-tr-10 p-2">
              <div className="border-t border-b border-dotted">
                <HTitle Type="h2">Oops!</HTitle>
                <p className="text-2xl font-thin">It seems you followed a broken link...</p>
              </div>
            </div>
          </div>
        </HeaderImage>
      </Row>
    </Layout>
  </>
)

export const query = graphql`
  {
    logo: file(relativePath: { eq: "abstract-404.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Page
